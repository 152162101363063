import ajax from '../ajax.js';
// 上传图片
export const systemUploadImg = (query) => {
  return ajax.post('/sysConfig/uploadImg', query);
};

// 首页
export const schHomeReport = (query) => {
  return ajax.get('/sch/home/report', {
    params: query,
  });
};

export const schHomeReport1 = (query) => {
  return ajax.get('/sch/home/report1', {
    params: query,
  });
};

export const schHomeGetUntreatedTotal = (query) => {
  return ajax.get('/sch/home/getUntreatedTotal', {
    params: query,
  });
};

// 学员
export const drvStudentGetList = (query) => {
  return ajax.get('/drvStudent/getList', {
    params: query,
  });
};

export const drvStudentGetStudent = (query) => {
  return ajax.get('/drvStudent/getStudent', {
    params: query,
  });
};

export const drvStudentGetStudentFlow = (query) => {
  return ajax.get('/drvStudent/getStudentFlow', {
    params: query,
  });
};

export const drvStudentExportStudentList = (query) => {
  return ajax.get('/drvStudent/exportStudentList', {
    params: query,
    responseType: 'blob',
  });
};

export const drvStudentExportStudentFlow = (query) => {
  return ajax.get('/drvStudent/exportStudentFlow', {
    params: query,
    responseType: 'blob',
  });
};

export const drvStudentRefreshStudentExamResult = (query) => {
  return ajax.get('/drvStudent/refreshStudentExamResult', {
    params: query,
  });
};

// 考试信息
export const schExamMsgGetList = (query) => {
  return ajax.get('/sch/examMsg/getList', {
    params: query,
  });
};

export const schExamMsgGetBySubjectsId = (query) => {
  return ajax.get('/sch/examMsg/getBySubjectsId', {
    params: query,
  });
};

export const schExamMsgExportGetBySubjectsId = (query) => {
  return ajax.get('/sch/examMsg/exportGetBySubjectsId', {
    params: query,
    responseType: 'blob',
  });
};

export const schExamMsgGetBySubjectsIdCounts = (query) => {
  return ajax.get('/sch/examMsg/getBySubjectsIdCounts', {
    params: query,
  });
};

// 统筹补偿
export const schReconciliationGetByCompensationList = (query) => {
  return ajax.get('/sch/reconciliation/getByCompensationList', {
    params: query,
  });
};

export const schReconciliationExportGetByCompensationList = (query) => {
  return ajax.get('/sch/reconciliation/exportGetByCompensationList', {
    params: query,
    responseType: 'blob',
  });
};

export const schReconciliationApplyForCompensation = (query) => {
  return ajax.post('/sch/reconciliation/applyForCompensation', query);
};

export const schReconciliationGetByCompensationListMsg = (query) => {
  return ajax.get('/sch/reconciliation/getByCompensationListMsg', {
    params: query,
  });
};

// 余额变更明细
export const schBalanceGetList = (query) => {
  return ajax.get('/sch/balance/getList', {
    params: query,
  });
};

export const schBalanceGetById = (query) => {
  return ajax.get('/sch/balance/getById', {
    params: query,
  });
};

export const schBalanceExportGetList = (query) => {
  return ajax.get('/sch/balance/exportGetList', {
    params: query,
    responseType: 'blob',
  });
};

export const sysConfigGetCompensation = (query) => {
  return ajax.get('/sysConfig/getCompensation', {
    params: query,
  });
};

// 系统设置
// 修改密码
export const schSysUpdPassWord = (query) => {
  return ajax.post('/sysUser/updPassWord', query);
};

export const sysUserLogin = (query) => {
  return ajax.post('/sysUser/login', query);
};

export const sysUserLogout = (query) => {
  return ajax.post('/sysUser/logout', query);
};

// 用户登录验证
export const sysUserVerify = (query) => {
  return ajax.post('/sysUser/verify', query);
};

export const schSchoolGetExpenseTemplate = (query) => {
  return ajax.get('/sch/school/getExpenseTemplate', {
    params: query,
  });
};

export const drvStudentRefund = (query) => {
  return ajax.post('/drvStudent/refund', query);
};

// 学员列表 - V 1.05版本
export const schStudentImportStudent = (query) => {
  return ajax.post('/sch/student/importStudent', query);
};

export const schStudentGetList = (query) => {
  return ajax.get('/sch/student/getList', {
    params: query,
  });
};

export const schStudentGetPolicyById = (query) => {
  return ajax.get('/sch/student/getPolicyById', {
    params: query,
  });
};

export const schStudentDelSchStudent = (query) => {
  return ajax.post('/sch/student/delSchStudent', query);
};

// return ajax.post('/sch/student/exportStudentFlow', query, {responseType: 'blob'});
export const schStudentExportStudentFlow = (query) => {
  return ajax.get('/sch/student/exportStudentFlow', {
    params: query,
    responseType: 'blob',
  });
};

//创建学员
export const schStudentAdd = (query) => {
  return ajax.post('/sch/student/add', query);
};

//购买服务
export const schStudentBuy = (query) => {
  return ajax.post('/sch/student/buy', query);
};

//申请补偿
export const schStudentApplyCompensation = (query) => {
  return ajax.post('/sch/student/applyCompensation', query);
};

// 服务补偿 - V 1.05版本
export const schDrvCompensateApplyGetList = (query) => {
  return ajax.get('/sch/drvCompensateApply/getList', {
    params: query,
  });
};

export const schDrvCompensateApplyGetApplyDetails = (query) => {
  return ajax.get('/sch/drvCompensateApply/getApplyDetails', {
    params: query,
  });
};

export const schDrvCompensateApplyDelApply = (query) => {
  return ajax.post('/sch/drvCompensateApply/delApply', query);
};

export const schDrvCompensateApplySubmitApply = (query) => {
  return ajax.post('/sch/drvCompensateApply/submitApply', query);
};

// 服务对账单 - V 1.05版本
export const schReconciliationGetList = (query) => {
  return ajax.get('/sch/reconciliation/getList', {
    params: query,
  });
};

export const schReconciliationGetDetails = (query) => {
  return ajax.get('/sch/reconciliation/getDetails', {
    params: query,
  });
};

export const schReconciliationExportGetList = (query) => {
  return ajax.get('/sch/reconciliation/exportGetList', {
    params: query,
    responseType: 'blob',
  });
};
